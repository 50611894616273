<template>
  <v-container fluid class="service-detail-height overflow-auto">
    <v-row>
      <v-col cols="6" class="pb-0 pl-4 left-side-border">
        <perfect-scrollbar
          :options="{ suppressScrollX: true }"
          class="scroll"
          style="position: relative"
        >
          <table class="width-100">
            <tr>
              <th colspan="2" class="font-size-18 py-2">Basic Information</th>
            </tr>
            <!--       <pre>{{ detail }}</pre> -->
            <tr>
              <td class="font-size-18 font-weight-500 py-2" width="250">
                Reference #
              </td>
              <td class="font-weight-600 font-size-18 py-2">
                <span v-if="detail && detail.reference">{{
                  detail.reference
                }}</span>
                <em v-else class="text--secondary"> no reference </em>
              </td>
            </tr>
            <tr>
              <td class="font-size-18 font-weight-500 py-2" width="250">
                Type
              </td>
              <td class="font-weight-600 font-size-18 py-2">
                <span v-if="detail && detail.type">{{ detail.type }}</span>
                <em v-else class="text--secondary"> no type </em>
              </td>
            </tr>
            <tr>
              <td class="font-size-18 font-weight-500 py-2" width="250">
                Date
              </td>
              <td class="font-weight-600 font-size-18 py-2">
                <span v-if="detail && detail.date">
                  {{ formatDate(detail.date) }}</span
                >
                <em v-else class="text--secondary"> no date </em>
              </td>
            </tr>
            <tr>
              <td class="font-size-18 font-weight-500 py-2 d-flex" width="250">
                Reason
              </td>
              <td class="font-weight-600 font-size-18 py-2">
                <span v-if="detail && detail.reason">{{ detail.reason }}</span>
                <em v-else class="text--secondary"> no reason </em>
              </td>
            </tr>

            <tr>
              <td class="font-size-18 font-weight-500 py-2 d-flex" width="250">
                Description
              </td>
              <td class="font-weight-600 font-size-18 py-2">
                <span v-if="detail && detail.description">{{
                  detail.description
                }}</span>
                <em v-else class="text--secondary"> no description </em>
              </td>
            </tr>
            <tr v-if="false">
              <td class="font-size-18 font-weight-500 py-2" width="250">
                Manage Stock
              </td>
              <td class="font-weight-600 font-size-18 py-2">
                <template v-if="dataLoading">
                  <v-skeleton-loader
                    class="custom-skeleton width-80"
                    type="text"
                  >
                  </v-skeleton-loader>
                </template>
                <template v-else>
                  {{ detail.manage_stock ? "Yes" : "No" }}
                </template>
              </td>
            </tr>
            <tr v-if="false">
              <td class="font-size-18 font-weight-500 py-2" width="250">
                Track Batch
              </td>
              <td class="font-weight-600 font-size-18 py-2">
                <template v-if="dataLoading">
                  <v-skeleton-loader
                    class="custom-skeleton width-80"
                    type="text"
                  >
                  </v-skeleton-loader>
                </template>
                <template v-else>
                  {{ detail.track_batch ? "Yes" : "No" }}
                </template>
              </td>
            </tr>
            <tr v-if="false">
              <td class="font-size-18 font-weight-500 py-2" width="250">
                Tax Preference
              </td>
              <td class="font-weight-600 font-size-18 py-2">
                <template v-if="dataLoading">
                  <v-skeleton-loader
                    class="custom-skeleton width-80"
                    type="text"
                  >
                  </v-skeleton-loader>
                </template>
                <template v-else>
                  {{ detail.is_taxable ? "Taxable" : "Non-Taxable" }}
                </template>
              </td>
            </tr>
            <tr v-if="false && detail.is_taxable">
              <td class="font-size-18 font-weight-500 py-2" width="250">Tax</td>
              <td class="font-weight-600 font-size-18 py-2">
                <template v-if="dataLoading">
                  <v-skeleton-loader
                    class="custom-skeleton width-80"
                    type="text"
                  >
                  </v-skeleton-loader>
                </template>
                <template v-else>
                  <template v-if="detail.tax"
                    >{{ detail.tax.name }} [{{
                      detail.tax.percentage
                    }}%]</template
                  >
                  <template v-else
                    ><em class="text--secondary">No Tax</em></template
                  >
                </template>
              </td>
            </tr>
            <tr v-if="false">
              <td class="font-size-18 font-weight-500 py-2" width="250">
                Tax Exemption
              </td>
              <td class="font-weight-600 font-size-18 py-2">
                <template v-if="dataLoading">
                  <v-skeleton-loader
                    class="custom-skeleton width-80"
                    type="text"
                  >
                  </v-skeleton-loader>
                </template>
                <template v-else>
                  <template v-if="detail.tax_exemption"
                    >{{ detail.tax_exemption.name }} [{{
                      detail.tax_exemption.reason
                    }}]</template
                  >
                  <template v-else
                    ><em class="text--secondary">No Tax Exemption</em></template
                  >
                </template>
              </td>
            </tr>
            <!-- <tr class="custom-border-top">
              <th colspan="2" class="font-size-18 py-2">Cost Information</th>
            </tr> -->
            <!-- <tr>
              <td class="font-size-18 font-weight-500 py-2" width="250">
                Cost Price
              </td>
              <td class="font-weight-600 font-size-18 py-2">
                <template v-if="dataLoading">
                  <v-skeleton-loader
                    class="custom-skeleton width-80"
                    type="text"
                  >
                  </v-skeleton-loader>
                </template>
                <template v-else>
                  {{
                    $accountingJS.formatMoney(detail.purchase_cost)
                  }}</template
                >
              </td>
            </tr> -->
            <!-- <tr>
              <td class="font-size-18 font-weight-500 py-2" width="250">
                Selling cost / Unit cost
              </td>
              <td class="font-weight-600 font-size-18 py-2">
                <template v-if="dataLoading">
                  <v-skeleton-loader
                    class="custom-skeleton width-80"
                    type="text"
                  >
                  </v-skeleton-loader>
                </template>
                <template v-else>
                  {{ $accountingJS.formatMoney(detail.selling_cost) }}</template
                >
              </td>
            </tr> -->
            <!-- <tr>
              <td class="font-size-18 font-weight-500 py-2" width="250">
                Project Price
              </td>
              <td class="font-weight-600 font-size-18 py-2">
                <template v-if="dataLoading">
                  <v-skeleton-loader
                    class="custom-skeleton width-80"
                    type="text"
                  >
                  </v-skeleton-loader>
                </template>
                <template v-else>
                  {{
                    $accountingJS.formatMoney(detail.project_price)
                  }}</template
                >
              </td>
            </tr> -->

            <!-- <tr>
              <td class="font-size-18 font-weight-500 py-2" width="250">
                Threshold Price
              </td>
              <td class="font-weight-600 font-size-18 py-2">
                <template v-if="dataLoading">
                  <v-skeleton-loader
                    class="custom-skeleton width-80"
                    type="text"
                  >
                  </v-skeleton-loader>
                </template>
                <template v-else>
                  {{
                    $accountingJS.formatMoney(detail.threshold_price)
                  }}</template
                >
              </td>
            </tr> -->

            <tr v-if="false">
              <td class="font-size-18 font-weight-500 py-2" width="250">
                Commission Rate (%)
              </td>
              <td class="font-weight-600 font-size-18 py-2">
                <template v-if="dataLoading">
                  <v-skeleton-loader
                    class="custom-skeleton width-80"
                    type="text"
                  >
                  </v-skeleton-loader>
                </template>
                <template v-else> {{ detail.commission_rate }}</template>
              </td>
            </tr>
          </table>
        </perfect-scrollbar>
      </v-col>
      <v-col cols="6" class="pb-0 gray-background pl-4">
        <table class="width-100">
          <tr>
            <th colspan="2" class="font-size-18 py-2">Supplier Information</th>
          </tr>

          <tr>
            <td class="font-size-18 font-weight-500 py-2" width="250">
              Supplier #
            </td>
            <td class="font-weight-600 font-size-18 py-2">
              <span
                v-if="
                  detail && detail.supplier_data && detail.supplier_data.barcode
                "
                >{{ detail.supplier_data.barcode }}</span
              >
              <em v-else class="text--secondary"> no supplier # </em>
            </td>
          </tr>
          <tr>
            <td class="font-size-18 font-weight-500 py-2" width="250">
              Display Name
            </td>
            <td class="font-weight-600 font-size-18 py-2">
              <span
                v-if="
                  detail &&
                  detail.supplier_data &&
                  detail.supplier_data.display_name
                "
                >{{ detail.supplier_data.display_name }}</span
              >
              <em v-else class="text--secondary"> no display name </em>
            </td>
          </tr>
        </table>
        <v-layout class="my-4">
          <v-flex md6 class="mr-2 custom-border-right">
            <table width="100%">
              <tr>
                <td class="font-weight-700 font-size-18">Contact details</td>
              </tr>
              <tr
                v-if="
                  detail.supplier_data && detail.supplier_data.default_person
                "
              >
                <td class="py-0 font-size-18 font-weight-500">
                  <span
                    class="mr-2"
                    v-if="detail.supplier_data.default_person.display_name"
                  >
                    <v-icon small>mdi-account</v-icon></span
                  >
                  {{ detail.supplier_data.default_person.display_name }}
                </td>
              </tr>
              <tr
                v-if="
                  detail.supplier_data && detail.supplier_data.default_person
                "
              >
                <td class="py-0 font-size-18 font-weight-500">
                  <span
                    class="mr-2"
                    v-if="detail.supplier_data.default_person.primary_phone"
                  >
                    <v-icon small>mdi-phone</v-icon></span
                  >
                  {{ detail.supplier_data.default_person.primary_phone }}
                </td>
              </tr>
              <tr v-else class="py-0 font-size-18 font-weight-500">
                <em class="text-muted ml-2"> no phone number</em>
              </tr>
              <tr
                v-if="
                  detail.supplier_data && detail.supplier_data.default_person
                "
              >
                <td class="py-0 font-size-18 font-weight-500">
                  <span
                    class="mr-2"
                    v-if="detail.supplier_data.default_person.primary_email"
                  >
                    <v-icon small>mdi-email</v-icon></span
                  >
                  {{ detail.supplier_data.default_person.primary_email }}
                </td>
              </tr>
              <tr v-else class="py-0 font-size-18 font-weight-500">
                <em class="text-muted ml-2"> no email address</em>
              </tr>
            </table>
          </v-flex>
          <v-flex md6 class="ml-2">
            <table width="100%">
              <tr>
                <td class="font-weight-700 font-size-18">
                  <label>Company Address</label>
                </td>
              </tr>
              <tr
                v-if="
                  detail &&
                  detail.supplier_data &&
                  detail.supplier_data.default_person &&
                  detail.supplier_data.default_person.supplier_address &&
                  detail.supplier_data.default_person.supplier_address
                    .property_address
                "
              >
                <td class="py-0 d-flex">
                  <span
                    class="mr-2"
                    v-if="
                      detail.supplier_data.default_person.supplier_address
                        .property_address
                    "
                  >
                    <v-icon small>mdi-map-marker</v-icon></span
                  >
                  <label
                    v-if="
                      detail.supplier_data.default_person.supplier_address
                        .property_address
                    "
                    >{{
                      detail.supplier_data.default_person.supplier_address
                        .street_1
                    }},
                    <br
                      v-if="
                        detail.supplier_data.default_person.supplier_address
                          .street_2 ||
                        detail.supplier_data.default_person.supplier_address
                          .unit_no
                      "
                    />
                    <template
                      v-if="
                        detail.supplier_data.default_person.supplier_address
                          .street_2
                      "
                    >
                      {{
                        detail.supplier_data.default_person.supplier_address
                          .street_2
                      }},
                    </template>
                    <template
                      v-if="
                        detail.supplier_data.default_person.supplier_address
                          .unit_no
                      "
                    >
                      {{
                        detail.supplier_data.default_person.supplier_address
                          .unit_no
                      }},
                    </template>
                    <br />
                    {{
                      detail.supplier_data.default_person.supplier_address
                        .country
                    }},
                    {{
                      detail.supplier_data.default_person.supplier_address
                        .zip_code
                    }},
                  </label>
                </td>
              </tr>
              <em v-else class="text-muted ml-2"> no company address</em>
            </table>
          </v-flex>
        </v-layout>
        <!-- <perfect-scrollbar
          ref="sidebarScrollbar"
          :options="{ suppressScrollX: true }"
          class="scroll"
          style="max-height: 89vh position: relative"
        >
          <table class="width-100">
            <tr>
              <th colspan="2" class="font-size-18 py-2">Pricing Information</th>
            </tr>

            <tr>
              <td class="font-size-18 font-weight-500 py-2" width="200">
                Selling Price/Unit Price
              </td>
              <td class="font-weight-600 font-size-18 py-2">
                <span class="text--uppercase pr-2 font-weight-700">{{
                  formatMoney(detail.sale_price)
                }}</span>
              </td>
            </tr>

            <tr>
              <td class="font-size-18 font-weight-500 py-2" width="200">
                Discounted price
              </td>
              <td class="font-weight-600 font-size-18 py-2">
                <span class="text--uppercase pr-2">{{
                  formatMoney(detail.discount_price)
                }}</span>
              </td>
            </tr>
            <br />
            <br />

            <tr>
              <th colspan="2" class="font-size-18 py-2">
                Quantity Information
              </th>
            </tr>
            <tr>
              <td class="font-size-18 font-weight-500 py-2" width="250">
                <v-tooltip top content-class="custom-top-tooltip">
                  <template v-slot:activator="{ on, attrs }">
                    <span v-bind="attrs" v-on="on" class="tooltip-border-dashed"
                      >Available Quantity</span
                    >
                  </template>
                  <span
                    >The available quantity for sale at<br />the beginning of
                    the accounting period.</span
                  >
                </v-tooltip>
              </td>
              <td class="font-weight-600 font-size-18 py-2">
                <template v-if="dataLoading">
                  <v-skeleton-loader
                    class="custom-skeleton width-80"
                    type="text"
                  >
                  </v-skeleton-loader>
                </template>
                <template v-else>
                  <span class="font-weight-700">{{
                    $accountingJS.formatNumber(detail.initial_stock)
                  }}</span></template
                >
              </td>
            </tr>
            <tr>
              <template v-if="detail.unit">
                <td class="font-size-18 font-weight-500 py-2" width="250">
                  UOM
                </td>
                <td class="font-weight-600 font-size-18 py-2">
                  <span v-if="detail && detail.unit"> {{ detail.unit }}</span>
                  <em v-else class="text--secondary"> no UOM</em>
                </td>
              </template>
            </tr>
            <br />
            <br />

            <tr>
              <th colspan="2" class="font-size-18 py-2">
                Warranty Information
              </th>
            </tr>


            <tr>
              <td
                class="font-size-18 font-weight-500 py-2"
                width="200"
                v-if="lodash.isEmpty(detail.warranty_data) === false"
              >
                Duration
              </td>
              <td
                class="font-weight-600 font-size-18 py-2"
                v-if="lodash.isEmpty(detail.warranty_data) === false"
              >
                <template v-for="(warranty, index) in detail.warranty_data">
                  <span
                    :key="'warranty-data-' + index"
                    class="text--uppercase pr-2"
                    >{{ warranty.value }} {{ warranty.field
                    }}<template v-if="warranty.value != 1">s</template></span
                  >
                </template>
              </td>
              <td v-else>
                <em class="text-muted"> not available</em>
              </td>
            </tr>

            <tr
              v-if="false"
              :class="{
                'custom-border-top':
                  lodash.isEmpty(detail.warranty_data) === false,
              }"
            >
              <th colspan="2" class="font-size-18 py-2">Accounting Stock</th>
            </tr>
            <tr v-if="false">
              <td class="font-size-18 font-weight-500 py-2" width="250">
                <v-tooltip top content-class="custom-top-tooltip">
                  <template v-slot:activator="{ on, attrs }">
                    <span v-bind="attrs" v-on="on" class="tooltip-border-dashed"
                      >Re-Order Level</span
                    >
                  </template>
                  <span
                    >When the stock reaches the reorder level, <br />a
                    notification will be sent to you.</span
                  >
                </v-tooltip>
              </td>
              <td class="font-weight-600 font-size-18 py-2">
                <template v-if="dataLoading">
                  <v-skeleton-loader
                    class="custom-skeleton width-80"
                    type="text"
                  >
                  </v-skeleton-loader>
                </template>
                <template v-else>{{
                  $accountingJS.formatNumber(detail.reorder_level)
                }}</template>
              </td>
            </tr>

            <tr v-if="false">
              <td class="font-size-18 font-weight-500 py-2" width="250">
                <v-tooltip top content-class="custom-top-tooltip">
                  <template v-slot:activator="{ on, attrs }">
                    <span v-bind="attrs" v-on="on" class="tooltip-border-dashed"
                      >Stock on Hand</span
                    >
                  </template>
                  <span>Current stock available for this item.</span>
                </v-tooltip>
              </td>
              <td class="font-weight-600 font-size-18 py-2">
                <template v-if="dataLoading">
                  <v-skeleton-loader
                    class="custom-skeleton width-80"
                    type="text"
                  >
                  </v-skeleton-loader>
                </template>
                <template v-else>{{
                  $accountingJS.formatNumber(detail.stock_on_hand)
                }}</template>
              </td>
            </tr>
            <tr v-if="false">
              <td class="font-size-18 font-weight-500 py-2" width="250">
                <v-tooltip top content-class="custom-top-tooltip">
                  <template v-slot:activator="{ on, attrs }">
                    <span v-bind="attrs" v-on="on" class="tooltip-border-dashed"
                      >Committed Stock</span
                    >
                  </template>
                  <span
                    >Stock that is committed to job(s) but not yet
                    invoiced.</span
                  >
                </v-tooltip>
              </td>
              <td class="font-weight-600 font-size-18 py-2">
                <template v-if="dataLoading">
                  <v-skeleton-loader
                    class="custom-skeleton width-80"
                    type="text"
                  >
                  </v-skeleton-loader>
                </template>
                <template v-else>{{
                  $accountingJS.formatNumber(detail.committed_stock)
                }}</template>
              </td>
            </tr>
            <tr v-if="false">
              <td class="font-size-18 font-weight-500 py-2" width="250">
                <v-tooltip top content-class="custom-top-tooltip">
                  <template v-slot:activator="{ on, attrs }">
                    <span v-bind="attrs" v-on="on" class="tooltip-border-dashed"
                      >Available for Sale</span
                    >
                  </template>
                  <span
                    >Available for sale = Stock on Hand - Committed Stock.</span
                  >
                </v-tooltip>
              </td>
              <td class="font-weight-600 font-size-18 py-2">
                <template v-if="dataLoading">
                  <v-skeleton-loader
                    class="custom-skeleton width-80"
                    type="text"
                  >
                  </v-skeleton-loader>
                </template>
                <template v-else>{{
                  $accountingJS.formatNumber(detail.available_stock)
                }}</template>
              </td>
            </tr>
          </table>

          <div
            v-if="false"
            class="mt-4"
            :class="{
              'custom-border-top':
                lodash.isEmpty(detail.warranty_data) === false,
            }"
          >
            <v-row>
              <v-col class="pt-0" md="8">
                <p class="font-size-18 font-weight-700 py-2 pl-1 my-0">
                  Product Summary
                </p>
              </v-col>
              <v-col class="pt-0" md="4">
                <v-select
                  :items="summaryFilter"
                  :disabled="dataLoading"
                  :loading="dataLoading"
                  v-model="summary"
                  hide-details
                  color="cyan"
                  item-color="cyan"
                  class="pt-0 mt-0 custom-appex-filter"
                  item-text="text"
                  item-value="value"
                  v-on:change="getSummary(true)"
                >
                </v-select>
              </v-col>
              <v-col md="6" offset-md="3" v-if="dataLoading">
                <v-progress-linear
                  color="cyan"
                  class="my-20"
                  indeterminate
                  rounded
                  height="6"
                ></v-progress-linear>
              </v-col>
              <v-col md="12" v-else class="text-center">
                <template v-if="series.length > 0">
                  <apexchart
                    type="bar"
                    :options="chartOptions"
                    :series="series"
                  ></apexchart>
                </template>
                <template v-else
                  ><em class="text--secondary font-size-16"
                    >Nothing Found...</em
                  ></template
                >
              </v-col>
            </v-row>
          </div>
        </perfect-scrollbar> -->
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" class="pb-0 pl-4"> </v-col>
    </v-row>
  </v-container>
</template>

<script>
import moment from "moment-timezone";
import { QUERY } from "@/core/services/store/request.module";
import CommonMixin from "@/core/plugins/common-mixin";
moment.tz.setDefault(process.env.VUE_APP_TIMEZONE);

export default {
  mixins: [CommonMixin],
  data() {
    return {
      product: 0,
      dataLoading: true,
      summary: "this_week",
      summaryFilter: [
        { text: "This Week", value: "this_week" },
        { text: "Previous Week", value: "previous_week" },
        { text: "This Month", value: "this_month" },
        { text: "Previous Month", value: "previous_month" },
        /*{ text: "This Quarter", value: "this_quarter" },
        { text: "Previous Quarter", value: "previous_quarter" },*/
        { text: "This Year", value: "this_year" },
        { text: "Previous Year", value: "previous_year" },
      ],
      chartOptions: {
        chart: {
          id: "product-summary",
        },
        xaxis: {
          categories: [],
        },
        dataLabels: {
          enabled: true,
          style: {
            fontFamily: "'Poppins', Helvetica, Arial, sans-serif",
          },
        },
      },
      series: [],
      tOneFields: [
        {
          title: "Name",
          key: "name",
          child: false,
        },
        /*{
          title: "Reference",
          key: "reference",
          child: false
        },*/
        {
          title: "Item Code",
          key: "barcode",
          child: false,
        },
        /*{
          title: "SKU",
          key: "sku",
          child: false
        },
        {
          title: "UPC",
          key: "upc",
          child: false
        },
        {
          title: "EAN",
          key: "ean",
          child: false
        },
        {
          title: "ISBN",
          key: "isbn",
          child: false
        },*/
        {
          title: "Part Number",
          key: "part_number",
          child: false,
        },
        /*  {
          title: "Serial Number",
          key: "serial_number",
          child: false,
        }, */
        /*{
          title: "HSN Code",
          key: "hsn_code",
          child: false
        },*/
        {
          title: "Description",
          key: "description",
          child: false,
        },
        /*{
          title: "Supplier",
          key: "supplier",
          child: "display_name",
        },*/
        {
          title: "Brand",
          key: "brand",
          child: "text",
        },
        /* {
          title: "Manufacturer",
          key: "manufacturer",
          child: "text",
        }, */
        {
          title: "Category",
          key: "category",
          child: "text",
        },
        /* {
          title: "UOM",
          key: "uom",
          child: "text",
        }, */
      ],
    };
  },
  props: {
    detail: {
      type: Object,
      required: true,
      default: () => {
        return new Object();
      },
    },
  },
  watch: {
    detail: {
      deep: true,
      immediate: true,
      handler(param) {
        this.product = this.lodash.toSafeInteger(param.id);
        // this.getSummary(false);
      },
    },
  },
  methods: {
    getSummary(scrollBottom) {
      const _this = this;
      if (_this.product > 0) {
        _this.dataLoading = true;
        _this.series = [];
        _this.$store
          .dispatch(QUERY, {
            url: "product/" + _this.product + "/summary",
            data: {
              filter: _this.summary,
            },
          })
          .then(({ data }) => {
            let categories = Object.values(data.categories);
            if (
              _this.lodash.isEmpty(categories) === false &&
              _this.lodash.isArray(categories)
            ) {
              _this.chartOptions = {
                ..._this.chartOptions,
                ...{
                  xaxis: {
                    categories: categories.map((row) => {
                      if (
                        _this.summary == "this_year" ||
                        _this.summary == "previous_year"
                      ) {
                        return moment(row).format("MMMM, YYYY");
                      }
                      return moment(row).format("Do, MMMM, YYYY");
                    }),
                  },
                },
              };

              if (_this.lodash.isEmpty(data.series) === false) {
                if (
                  _this.lodash.isEmpty(data.series.quotation_counted) === false
                ) {
                  _this.series.push({
                    name: "Quotation",
                    data: Object.values(data.series.quotation_counted),
                  });
                }
                if (
                  _this.lodash.isEmpty(data.series.invoice_counted) === false
                ) {
                  _this.series.push({
                    name: "Invoice",
                    data: Object.values(data.series.invoice_counted),
                  });
                }
                if (_this.lodash.isEmpty(data.series.job_counted) === false) {
                  _this.series.push({
                    name: "Job",
                    data: Object.values(data.series.job_counted),
                  });
                }
              }
            }
          })
          .catch((error) => {
            _this.logError(error);
          })
          .finally(() => {
            _this.dataLoading = false;
            if (_this.lodash.isEmpty(_this.series) === false && scrollBottom) {
              let objDiv = _this.$refs["sidebarScrollbar"].$el;
              _this.$nextTick(() => {
                objDiv.scrollTop = objDiv.scrollHeight;
              });
            }
          });
      }
    },
  },
  mounted() {
    // this.getSummary();
  },
};
</script>
