<template>
  <div>
    <v-layout class="p-4 border-bottom-light-grey min-height-57px">
      <v-flex class="font-level-3-bold my-auto">
        <span class="detail-svg-icon mr-2">
          <!--begin::Svg Icon-->
          <v-icon color="blue darken-4">mdi-account-group</v-icon>
          <!--end::Svg Icon-->
        </span>
        Suppliers
        <!-- <pre>{{product}}</pre> -->
      </v-flex>
    </v-layout>
    <div
      class="overflow-y"
      style="max-height: calc(100vh - 306px)"
      v-if="!pageLoading"
    >
      <template v-if="product">
        <table
          width="100%"
          class="detail-table table-head-sticky"
          style="table-layout:'fixed"
        >
          <thead>
            <tr>
              <th width="20%" class="p-2 blue lighten-4 text-left">#</th>
              <th width="20%" class="p-2 blue lighten-4">Name</th>
              <th width="20%" class="p-2 blue lighten-4">Company Name</th>
              <th width="20%" class="p-2 blue lighten-4 text-center">Email</th>
              <th width="20%" class="p-2 blue lighten-4 text-center">
                Phone Number
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td class="p-2 border-top-light-grey text-left">
                <div v-if="product && product.barcode" class="m-2">
                  <span class="fw-500" v-on:click="RequestPoDetail(product.id)">
                    <Barcode :barcode="product.barcode"></Barcode>
                  </span>
                </div>
              </td>
              <td class="p-2 border-top-light-grey">
                {{ product.display_name }}
                <!-- <ShowValue :object="product" object-key="display_name" label="display name"></ShowValue> -->
              </td>
              <td class="p-2 border-top-light-grey">
                <template v-if="product.company_name">
                  {{ product.company_name }}
                </template>
                <template v-else>
                  <em class="text-muted">no company name</em>
                </template>
              </td>
              <td class="p-2 border-top-light-grey text-center">
                <template v-if="product.company_email">
                  {{ product.company_email }}
                </template>
                <template v-else>
                  <em class="text-muted">no email</em>
                </template>
              </td>
              <td class="p-2 border-top-light-grey text-center">
                <template v-if="product.company_number">
                  {{ product.company_number }}
                </template>
                <template v-else>
                  <em class="text-muted">no phone number</em>
                </template>
              </td>
            </tr>
          </tbody>
        </table>
      </template>
      <p v-else class="m-0 row-not-found text-center">
        <img
          :src="$assetURL('media/error/empty.png')"
          class="row-not-found-image"
        />
        Uhh... There are no supplier at the moment.
      </p>
    </div>
    <div v-else class="mx-20 my-40 text-center">
      <v-progress-linear
        color="orange darken-2"
        indeterminate
        height="6"
      ></v-progress-linear>
    </div>
  </div>
</template>
<script>
//import ApiService from "@/core/services/api.service";
//import ShowValue from "@/view/components/ShowValue";
// import ImageTemplate from "@/view/components/Image";
import /* GET */ "@/core/services/store/request.module";
import Barcode from "@/view/pages/partials/Barcode.vue";
import CommonMixin from "@/core/plugins/common-mixin";
export default {
  mixins: [CommonMixin],
  name: "SupplierListing",
  props: {
    typeText: {
      type: String,
      default: null,
    },
    type: {
      type: String,
      default: null,
    },
    typeUuid: {
      type: String,
      default: null,
    },
    product: {
      type: Object,
      default() {
        return new Object();
      },
    },
  },
  data() {
    return {
      supplers: [],
      pageLoading: false,
    };
  },
  methods: {
    RequestPoDetail(uuid) {
      //console.log(uuid);
      this.$router.replace(
        this.getDefaultRoute("supplier.detail", {
          params: { id: uuid },
        })
      );
    },

    // getSupplier() {
    // 	ApiService.get(`${this.type}/${this.typeUuid}/suppliers`)
    // 		.then(({ data }) => {
    // 			this.supplers = data.data;
    // 		})
    // 		.catch((error) => {
    // 			this.$emit("error", error);
    // 		})
    // 		.finally(() => {
    // 			this.pageLoading = false;
    // 		});
    // },
    /*  getSupplier() {
      this.$store
        .dispatch(GET, {
          url: "product/" + this.product + "/suppliers",
        })
        .then(({ data }) => {
          this.supplers = data.data;
        })
        .catch((error) => {
          this.$emit("error", error);
        })
        .finally(() => {
          this.pageLoading = false;
        });
    }, */
  },
  components: {
    //ShowValue,
    Barcode,
  },
  mounted() {
    //this.getSupplier();
  },
};
</script>
